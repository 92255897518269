import React, { Component } from 'react';
import './desktop.css'
export default class Desktop extends Component {
    render() {
        return (
            <div className="content">
                <div className="spacer"></div>
                <div className="fivegWrap">
                    <div className="fiveWrap">
                        <p className="five">5</p>
                    </div>
                    <div className="gWrap">
                        <p className="g">G</p>
                    </div>
                </div>
                <div className="centerBar"></div>
                <div className="infoWrap">
                <div className="upperi">
                    <p className="hakron">HAKRON</p>
                    <p className="generation">5th Generation</p>
                </div>
                <div className="middlei">
                    <p className="middleText">Come celebrate HAkron's 5th annual hackathon with us! A free 24 hour coding event, where students compete for awesome prizes while getting to meet with recruiting companies</p>
                </div>
                <div className="bottomi">
                    <p className="bottomText">Our event will be held early in Fall 2020. Check back in March to register!</p>
                    <p className="bottomText">Contact us at <a className="email" href="mailto:team@hakron.io">team@hakron.io</a></p>
                </div>
                </div>
                <div className="footer">
                    <p className="footertxt">Looking for our club website? Follow this link <a href="https://www.notion.so/Club-Homepage-2a26c7aede5845f0bef7e87b19ec81b3">here</a></p>
                </div>
            </div>
        );
    }
}
