import React, { Component } from 'react';
import './mobile.css';

export default class Mobile extends Component {
    render() {
        return (
            <div className="mcontent">
                <div className="mhead">
                    <p className="mhakron">HAKRON</p>
                    <p className="mfiveg">5G</p>
                </div>
                <div className="mbar"/>
                <div className="mupperi">
                    <p className="mtext">Come celebrate the 5th generation of HAkron's annual hackathon with us! A free 24 hour coding event, where students compete for awesome prizes while getting to meet with recruiting companies</p>
                </div>
                <div className="mloweri">
                    <p className="mtext">Our event will be held early in Fall 2020. Check back in March to register!</p>
                    <p className="mtext">Contact us at <a className="email" href="mailto:team@hakron.io">team@hakron.io</a></p>
                </div>
                <div className="footer">
                    <p className="footertxt">Looking for our club website? Follow this link <a href="https://www.notion.so/Club-Homepage-2a26c7aede5845f0bef7e87b19ec81b3">here</a></p>
                </div>
            </div>
        );
    }
}
