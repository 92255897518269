import React from 'react';
import './App.css';
import { useState, useEffect } from 'react';
import Mobile from './components/mobile.js';
import Desktop from './components/desktop.js';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowDimensions;
}

function App() {
  var { height, width } = useWindowDimensions();
  if (height < 1.4*width) {
    return (
      <div className="App">
        <Desktop/>
      </div>
    );
  }
  else {
    return (
      <div className="App">
        <Mobile/>
      </div>
    )
  }
}

export default App;
